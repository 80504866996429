class ToggleStateManager {
    constructor(element) {
        this.element = element;
        this.groupIdentifier = 'refinementsState';
        this.identifier = this.getIdentifier();
    }

    init() {
        this.element.addEventListener('click', () => this.toggleState());
    }

    getIdentifier() {
        return this.element.getAttribute('data-bs-target')?.replace('#', '');
    }

    getStoredStates() {
        const stored = localStorage.getItem(this.groupIdentifier);
        return stored ? JSON.parse(stored) : {};
    }

    toggleState() {
        const newState = this.element.getAttribute('aria-expanded') === 'true';

        const states = this.getStoredStates();
        states[this.identifier] = newState;
        localStorage.setItem(this.groupIdentifier, JSON.stringify(states));
    }
}

module.exports = function () {
    const toggleStateManagerInit = () => {
        const togglerElements = document.querySelectorAll('.js-bs-toggle-collapse');

        togglerElements.forEach(togglerElement => {
            const toggleStateManager = new ToggleStateManager(togglerElement);
            toggleStateManager.init();
        });
    };

    toggleStateManagerInit();
    document.addEventListener('search:filtered', () => toggleStateManagerInit());
};
