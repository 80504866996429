'use strict';

const scrollToElement = require('./scrollToElement');

const addScrollToListeners = function () {
    document.addEventListener('click', (event) => {
        const trigger = event.target.closest('[data-scroll-to]');

        if (!trigger || !document.contains(trigger)) {
            return;
        }

        event.preventDefault();
        const targetSelector = trigger.getAttribute('data-scroll-to');

        if (!targetSelector) {
            return;
        }

        const target = document.querySelector(targetSelector);

        if (target) {
            scrollToElement(target);
        }
    });
};

module.exports = function () {
    addScrollToListeners();
};
